body, html {
    margin: 0;
    padding: 0;
    height: 100%;
  }
  
  .contact-container {
    background-color: #f8f9fa; 
    padding: 40px; 
    box-sizing: border-box;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    min-height: 100vh; 
    border-radius: 8px; 
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1); 
    max-width: 600px; 
    margin: 20px auto; 
  }
  
  .contact-container h2 {
    font-size: 2.5rem; 
    color: #035214;
    margin-bottom: 20px; 
  }
  
  .contact-container form {
    display: flex;
    flex-direction: column; 
    width: 100%; 
  }
  
  .contact-container label {
    margin-bottom: 5px;
    font-weight: bold;
  }
  
  .contact-container input,
  .contact-container textarea {
    padding: 10px;
    margin-bottom: 15px;
    border: 1px solid #ddd;
    border-radius: 4px;
    font-size: 1em;
  }
  
  .contact-container button {
    padding: 10px;
    background-color: #035214;
    color: white;
    border: none;
    border-radius: 4px;
    font-size: 1em;
    cursor: pointer;
  }
  
  .contact-container button:hover {
    background-color: #01390d;
  }
  
  
  @media only screen and (max-width: 768px) {
    .contact-container h2 {
      font-size: 2rem; 
    }
  
    .contact-container input,
    .contact-container textarea {
      font-size: 0.9em;
    }
  }