.hero-section {
  background-color: rgb(245, 250, 245);
  color: #141414;
  text-align: center;
  padding: 80px 20px;
  margin-bottom: 20px;
  width: 100%;
  box-sizing: border-box;
  overflow: hidden;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  opacity: 0;
  transform: translateY(20px);
  transition: opacity 0.8s ease-in-out, transform 0.8s ease-in-out;
  z-index: 3;
  position: relative; /* Add position relative for absolute positioning */
}

.loaded {
  opacity: 1;
  transform: translateY(0);
}

.hero-content {
  position: relative;
  z-index: 1; /* Ensure content is above the background */
}

.hero-heading {
  font-size: 3em;
  margin-bottom: 10px;
  font-weight: bold;
}

.hero-subheading {
  font-size: 1.8em;
  margin-bottom: 20px;
  color: #035214;
}

.hero-message {
  font-size: 1.4em;
  line-height: 1.6;
  margin-bottom: 20px;
}

.cta-button-container {
  margin-top: 30px;
  position: relative; /* Add position relative */
  z-index: 2; /* Set a higher z-index value */
}

.cta-button {
  background-color: #035214;
  color: #ffffff;
  padding: 15px 30px;
  font-size: 1.2em;
  text-decoration: none;
  border-radius: 5px;
  transition: background-color 0.3s ease-in-out;
  display: inline-block;
  position: relative; /* Add position relative */
}

.cta-button:hover {
  background-color: #2980b9;
}


/* Quote icon styles */
.quote-icon {
  position: absolute;
  top: 15px;
  left: 15px;
  font-size: 2em;
  color: #3498db;
}

/* Additional styles for responsiveness */
@media only screen and (max-width: 768px) {
  .hero-heading {
    font-size: 2.5em;
    padding-top: 100px;
  }

  .hero-subheading {
    font-size: 1.5em;
  }

  .hero-message {
    font-size: 1.2em;
  }

  .cta-button {
    font-size: 1em;
    padding: 12px 25px;
  }
  .hero-section {
    height: auto; /* Override the height property to make the section height adapt to its content */
  }
}
