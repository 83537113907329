.TranslationServices-container {
  background-color: white; /* Light background */
  padding: 40px;
  margin-top: 20px;
  width: 100%;
  height: 100vh;
  box-sizing: border-box; /* Ensures padding and border are included in the width */
  overflow: hidden; /* Prevents horizontal overflow */
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.TranslationServices-heading {
  font-size: 2.5em;
  margin-bottom: 20px;
  color: #2c3e50; /* Dark text color */
}

.TranslationServices-paragraph {
  font-size: 1.4em;
  line-height: 1.6;
  margin-bottom: 15px;
  color: #34495e; /* Slightly darker text color */
}

/* Additional styles for responsiveness */
@media only screen and (max-width: 768px) {
  .TranslationServices-container {
    height: 130vh; /* Change height on smaller devices */
  }

  .TranslationServices-heading {
    font-size: 2em;
  }

  .TranslationServices-paragraph {
    font-size: 1.2em;
  }
}
