.navbar {
  background-color: rgba(255, 255, 255, 0.986); /* Dark blue background */
  color: #ecf0f1; /* Light text color */
  padding: 15px, 0px;
  position: fixed;
  width: 100%;
  top: 0;
  z-index: 1000;
  transition: background-color 0.5s ease;
}

.scrolled {
  background-color: white; /* Change color on scroll */
}

.nav-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px
}

.logo-container {
  cursor: pointer;
}

.logo {
  max-width: 200px; /* Adjust logo size for desktop */
}

.menu-toggle {
  display: none;
  flex-direction: column;
  cursor: pointer;
  margin-right: 20px; /* Add margin to separate toggle from links */
}

.menu-toggle .bar {
  width: 25px;
  height: 3px;
  background-color: #ecf0f1;
  margin: 4px 0;
  transition: transform 0.3s ease;
}

.menu-toggle.open .bar:nth-child(1) {
  transform: translateY(8px) rotate(45deg);
}

.menu-toggle.open .bar:nth-child(2) {
  opacity: 0;
}

.menu-toggle.open .bar:nth-child(3) {
  transform: translateY(-8px) rotate(-45deg);
}

.nav-links {
  display: flex;
  list-style: none;
  margin: 0;
  padding: 0;
}

.nav-links.open {
  display: flex;
  flex-direction: column;
  position: absolute;
  top: 100%; /* Position dropdown below the toggle */
  left: 0;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.8); /* Adjust background color */
  padding: 20px 0;
  transition: transform 0.3s ease;
  z-index: 999; /* Ensure dropdown is above other content */
}

.nav-links li {
  margin-right: 20px;
}

.nav-links li:last-child {
  margin-right: 0;
}

.nav-links a {
  text-decoration: none;
  color: black;
  font-size: 1.2em;
  transition: color 0.3s ease;
}

.nav-links a:hover {
  color: #0f0f0f;
  text-shadow: 0 0 10px #36b661, 0 0 20px #e6e9e7, 0 0 30px #209447;
}


.nav-links li::after {
  content: '';
  position: absolute;
  width: 0;
  height: 8px; /* Blob height */
  bottom: -5px;
  left: 50%;
  transform: translateX(-50%);
  background-color: #3498db; /* Default blob color */
  border-radius: 50%;
  transition: all 0.3s ease-in-out;
}

@media (max-width: 768px) {
 

  .menu-toggle {
    display: flex;
  }

  .nav-links {
    display: none;
  }

  .nav-links.open {
    display: flex;
  }

  .nav-links li {
    margin: 15px 0;
    text-align: center;
  }

  .nav-links a {
    color: #ecf0f1; /* Adjust link color */
    font-size: 1.2em;
  }
}
