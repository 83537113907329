body, html {
  margin: 0;
  padding: 0;
  height: 100%;
}

.copywriting-services-container {
  background-color: #f8f9fa; /* Light background color */
  padding: 40px;
  box-sizing: border-box; /* Ensures padding and border are included in the width */
  overflow: hidden; /* Prevents horizontal overflow */
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: 100vh; /* Minimum height of the viewport */
}

.copywriting-services-heading {
  font-size: 2.5rem;
  color: #035214;
  margin-bottom: 20px;
}

.copywriting-services-paragraph {
  font-size: 1.4rem;
  color: #343a40; /* Dark text color */
  margin-top: 20px;
  line-height: 1.6;
}

/* Additional styles for responsiveness */
@media only screen and (max-width: 768px) {
  .copywriting-services-heading {
    font-size: 2rem;
  }

  .copywriting-services-paragraph {
    font-size: 1.2rem;
  }
}
